.loading {
  padding: 20px 0;
}
.loading .spinner {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
  border: 8px solid #fff; /* Light grey */
  border-top: 8px solid #333; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading .caption {
  font-size: 14px;
}
